import { logout } from "../../Firebase/firebase";
import Button from "react-bootstrap/Button";

const LogoutButton = () => {
  return (
    <Button className="home_button" onClick={logout}>
      Logout
    </Button>
  );
};

export default LogoutButton;
