import { type } from "@testing-library/user-event/dist/type";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  getDoc,
  collection,
  where,
  addDoc,
  doc,
} from "firebase/firestore";

//todo: change to ugp-1 config and figure out how to store credentials to not make them public on github
const firebaseConfig = {
  apiKey: "AIzaSyDfDnc70TlTFyEuDRj-kxlI970acaB_wv0",
  authDomain: "automated-atlas-225914.firebaseapp.com",
  databaseURL: "https://automated-atlas-225914.firebaseio.com",
  projectId: "automated-atlas-225914",
  storageBucket: "automated-atlas-225914.appspot.com",
  messagingSenderId: "421199282430",
  appId: "1:421199282430:web:98ad632d3587f349",
  measurementId: "G-TT3B67VTJ0",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

type Props = {
  email: string;
  password: string;
};

const logInWithEmailAndPassword = async ({ email, password }: Props) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (exception: any) {
    alert(exception.message);
  }
};

const sendPasswordReset = async ({ email }: Props) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent to your email!");
  } catch (exception: any) {
    console.error(exception);
    alert(exception.message);
  }
};

const logout = async () => {
  await signOut(auth);
};

export {
  auth,
  db,
  logInWithEmailAndPassword,
  sendPasswordReset,
  logout,
  query,
  getDocs,
  getDoc,
  doc,
  where,
  collection,
};
