import { useEffect } from "react";
import Head from "../Components/Header";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Firebase/firebase";
import LogoutButton from "../Components/buttons/logout_button";

const NotAuthorized = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return navigate("/login");
    }
  });

  return (
    <>
      <div className="not_authorized">
        <Head />
        <h3>Not Authorized</h3>
        <LogoutButton />
      </div>
    </>
  );
};

export default NotAuthorized;
