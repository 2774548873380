import logo from "../Styling/Images/OptiMedHealth-logo.svg";

const Head = () => {
  return (
    <header className="header">
      <img src={logo} className="App-logo" alt="logo" />
      <h1>Redcard Request</h1>
    </header>
  );
};

export default Head;
