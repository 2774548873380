import { useEffect, useState } from "react";
import "../Styling/Login.css";
import {
  auth,
  logInWithEmailAndPassword,
  db,
  getDoc,
  doc,
} from "../Firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import LoginForm from "../Components/forms/login";
import Head from "../Components/Header";

const Login = () => {
  const [input, setInput] = useState({
    email: "",
    password: "",
    error: "",
  });
  const [user, loading] = useAuthState(auth);
  const [employeeStatus, setEmployeeStatus] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    checkEmployeeStatus();
    if (user && employeeStatus === "employee") {
      navigate("/");
    } else if (user && employeeStatus === "user") {
      navigate("/notauthorized");
    }
  }, [user, loading, employeeStatus, navigate]);

  const loginSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (validation()) {
      const email = input.email;
      const password = input.password;
      logInWithEmailAndPassword({ email, password });
    }
  };

  const checkEmployeeStatus = async () => {
    let userID: string = user ? user?.uid : "";

    if (userID === "") {
      return;
    }

    try {
      const docRef = doc(db, "users", userID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        docSnap.data().roles.employee
          ? setEmployeeStatus("employee")
          : setEmployeeStatus("user");
      } else {
        alert("You are not authorized to login");
      }
    } catch (exception) {
      alert("You are not authorized to login");
    }
  };

  const validation = () => {
    if (!input.email) {
      setInput({ ...input, error: "Please enter your username" });
      return false;
    }
    if (!input.password) {
      setInput({ ...input, error: "Please enter your password" });
      return false;
    }
    return true;
  };

  return (
    <>
      <Head />
      <div className="Login">
        <LoginForm
          input={input}
          loginSubmit={loginSubmit}
          setInput={setInput}
        />
      </div>
    </>
  );
};

export default Login;
