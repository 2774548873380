import { useState, useEffect } from "react";
import IDForm from "../Components/forms/memberID";
import Head from "../Components/Header";
import { addCard } from "../Server/services";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Firebase/firebase";
import LogoutButton from "../Components/buttons/logout_button";

const Home = () => {
  const [memberID, setMemberID] = useState("");
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return navigate("/login");
    }
  }, [user]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const requestUser = user?.email!;
    let response;
    await addCard({ memberID, requestUser }).then((data) => {
      response = data;
    });
    if (response === 404) {
      console.log(typeof response);
      alert("Invalid ID Entered.");
    } else if (response === 405) {
      alert("Member Already Queued.");
    } else if (response === 200) {
      alert("Member Added.");
    }
  };

  return (
    <>
      <div>
        <Head />
        <IDForm
          memberID={memberID}
          handleSubmit={handleSubmit}
          setMemberID={setMemberID}
        />
        <LogoutButton />
      </div>
    </>
  );
};

export default Home;
