import { useState, useEffect } from "react";
import "./Styling/App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/home";
import Login from "./Pages/login";
import NotAuthorized from "./Pages/not_authorized";

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("https://red-card-api-deployment-pduucvp2ea-uc.a.run.app/api")
      .then((res) => res.json())
      .then((data) => setData(data.message));
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/notauthorized" element={<NotAuthorized />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
