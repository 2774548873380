import '../../Styling/Home.css';

type Props = {
  memberID: string;
  handleSubmit: any;
  setMemberID: (text: string) => void;
};

const IDForm = ({ memberID, handleSubmit, setMemberID }: Props) => {
  return (
    <form onSubmit={handleSubmit}>
      <label>
        Member ID:
        <input
          type="text"
          name="member_id"
          id="member_id"
          onChange={(e) => setMemberID(e.target.value)}
          value={memberID}
        />
      </label>
      &nbsp;
      <button className='home_button' type="submit">Add</button>
    </form>
  );
};

export default IDForm;
