type Props = {
  memberID: string;
  requestUser?: string;
};

export const addCard = ({ memberID, requestUser }: Props) => {
  const info = { member_id: memberID, user: requestUser };
  return fetch(
    "https://red-card-api-deployment-pduucvp2ea-uc.a.run.app/add_card",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(info),
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    });
};
