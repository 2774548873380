import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

interface input {
  email: string;
  password: string;
  error: string;
}

interface FormProps {
  input: input;
  loginSubmit: any;
  setInput: any;
}

const LoginForm = ({ loginSubmit, input, setInput }: FormProps) => {
  return (
    <Form onSubmit={loginSubmit}>
      <div>
        <input
          type="email"
          className="login_textbox"
          id="email"
          value={input.email}
          name="EmailInput"
          aria-describedby="emailHelp"
          placeholder="Enter email"
          onChange={(event) =>
            setInput({ ...input, email: event.target.value, error: "" })
          }
        />
      </div>

      <br></br>

      <div>
        <input
          type="password"
          className="login_textbox"
          value={input.password}
          placeholder="Password"
          onChange={(event) =>
            setInput({ ...input, password: event.target.value, error: "" })
          }
        />
      </div>

      <small id="error" className="text-danger form-text">
        {input.error}
      </small>

      <br></br>

      <Button className="login_button" type="submit" variant="primary">
        Submit
      </Button>
    </Form>
  );
};

export default LoginForm;
